import * as Sentry from '@sentry/sveltekit';

try {
  const hostName = window.location.hostname
  let runSentry = hostName === 'staging.mykineticlab.com'
  runSentry = runSentry || hostName === 'localhost'

  if (!runSentry) {
    setTimeout(() => {
      Sentry.init({
        dsn: 'https://bd4eec4c241841a0857d6804d4cf3ac2@o133895.ingest.us.sentry.io/4504300753387520',
        tracesSampleRate: 1.0,
        // For instance, initialize Session Replay:
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [Sentry.replayIntegration(), Sentry.debugIntegration()],
      });
    }, 1)
  }
} catch (e) {
  console.log('not in browser from hooks')
}

const myErrorHandler = ({ error, event }) => {
  console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();